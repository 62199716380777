import classicRoom from '../../images/classic-room.jpeg';
import premierRoom from '../../images/premier-room.jpeg';
import premierLoft from '../../images/premier-loft.jpg';
import premierClassic from '../../images/premier-classic.png';
import deluxeCabin from '../../images/deluxe.jpg';
import Alert from '@mui/material/Alert';

import { HomeIcon, VideoCameraIcon, EmojiHappyIcon, HeartIcon } from '@heroicons/react/outline'

export default function RoomTypes() {
  return (
    <section className="w-full pt-7 pb-7 md:pt-20 md:pb-24">

      <Alert severity="info">
        Due to increase in gst, operational cost and rental we will increase our cabin rate as per below (starting from 3rd March 2024): <br></br>
        Classic cabin : $42/night <br></br>
        Premium Classic: $52/night <br></br>
        Deluxe Classic: $55/night <br></br>
        Premium suite: $62/night for first cat (2nd cat charge remains the same) <br></br>
        Premium loft : $70/night for first cat (2nd and 3rd cat charge remains the same) 
      </Alert>

      <div className="box-border flex flex-col items-center content-center px-4 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl">

        <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
          <img src={classicRoom} className="p-2 pl-6 pr-5" />
        </div>

        <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
          <h2 className="text-orange-500 m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
            {'Classic Cabin (for 1 guest only)'}
          </h2>
          <div>
            {'* Open for kittens or cats under 3kg only'}
          </div>
          <div className="flex items-end mt-3 mb-3 leading-7 text-orange-500 border-0 pb-4">
            <p className="box-border mr-1">
              SGD
            </p>
            <p className="box-border m-0 text-4xl font-semibold leading-none border-solid">
              42
            </p>
            <p className="box-border m-0 border-solid">
              {'/ night'}
            </p>
          </div>
          <div>
            <ul className="pl-6 space-y-1 text-brown">
              <li><HomeIcon className="w-6 h-6 inline mr-2 text-orange-400" />Room size: 1.2m x 0.6m x 0.6m</li>
              <li><VideoCameraIcon className="w-6 h-6 inline mr-2 text-orange-400" />Live Camera: Owner will be able to view your cat anytime & anywhere (one-time activation fee of $10)</li>
              <li><EmojiHappyIcon className="w-6 h-6 inline mr-2 text-orange-400" />24-hour air conditioned</li>
              <li><HeartIcon className="w-6 h-6 inline mr-2 text-orange-400" />30-60 minutes daily play</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="box-border flex flex-col items-center content-center px-4 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl">
        <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
          <h2 className="text-orange-500 m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
            {'Premium Classic Cabin (for 1 guest only)'}
          </h2>
          <div className="flex items-end mt-3 leading-7 text-orange-500 border-0">
            <p className="box-border mr-1">
              SGD
            </p>
            <p className="box-border m-0 text-4xl font-semibold leading-none border-solid">
              52
            </p>
            <p className="box-border m-0 border-solid">
              {'/ night'}
            </p>
          </div>
          <div className='mb-3 pb-4 pt-2'>
            <p className='text-brown'>
            </p>
          </div>
          <div>
            <ul className="pl-6 space-y-1 text-brown">
              <li><HomeIcon className="w-6 h-6 inline mr-2 text-orange-400" />Room size: 1.4m x 0.8m x 0.6m</li>
              <li><VideoCameraIcon className="w-6 h-6 inline mr-2 text-orange-400" />Live Camera: Owner will be able to view your cat anytime & anywhere (one-time activation fee of $10)</li>
              <li><EmojiHappyIcon className="w-6 h-6 inline mr-2 text-orange-400" />24-hour air conditioned</li>
              <li><HeartIcon className="w-6 h-6 inline mr-2 text-orange-400" />30-60 minutes daily play</li>
            </ul>
          </div>
        </div>

        <div className="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
          <img src={premierClassic} className="p-2 pl-6 pr-5" />
        </div>
      </div>

      <div className="box-border flex flex-col items-center content-center px-4 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl">
        <div className="box-border order-first md:order-last relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
          <img src={deluxeCabin} className="p-2 pl-6 pr-5" />
        </div>
        <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
          <h2 className="text-orange-500 m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
            {'Deluxe Cabin (for 1 guest only)'}
          </h2>
          <div className="flex items-end mt-3 mb-3 leading-7 text-orange-500 border-0">
            <p className="box-border mr-1">
              SGD
            </p>
            <p className="box-border m-0 text-4xl font-semibold leading-none border-solid">
              55
            </p>
            <p className="box-border m-0 border-solid">
              {'/ night'}
            </p>
          </div>
          <div>
            <ul className="pl-6 space-y-1 text-brown">
              <li><HomeIcon className="w-6 h-6 inline mr-2 text-orange-400" />Room size: 1.2m x 2.1m x 0.85m</li>
              <li><VideoCameraIcon className="w-6 h-6 inline mr-2 text-orange-400" />Live Camera: Owner will be able to view your cat anytime & anywhere (one-time activation fee of $10)</li>
              <li><EmojiHappyIcon className="w-6 h-6 inline mr-2 text-orange-400" />24-hour air conditioned</li>
              <li><HeartIcon className="w-6 h-6 inline mr-2 text-orange-400" />30-60 minutes daily play</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="box-border flex flex-col items-center content-center px-4 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl">
        <div className="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
          <img src={premierRoom} className="p-2 pl-6 pr-5" />
        </div>

        <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
          <h2 className="text-orange-500 m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
            {'Premium Suite (Up to 2 guests from same household)'}
          </h2>
          <div className="flex items-end mt-3 leading-7 text-orange-500 border-0">
            <p className="box-border mr-1">
              SGD
            </p>
            <p className="box-border m-0 text-4xl font-semibold leading-none border-solid">
              62
            </p>
            <p className="box-border m-0 border-solid">
              {'/ night'}
            </p>
          </div>
          <div className='mb-3 pb-4 pt-2'>
            <p className='text-brown'>
              <span className='super'>*</span> {'SGD 20/night for the 2nd Cat'}
            </p>
          </div>
          <div>
            <ul className="pl-6 space-y-1 text-brown">
              <li><HomeIcon className="w-6 h-6 inline mr-2 text-orange-400" />Room size: 2.4m x 1.2m x 0.6m</li>
              <li><VideoCameraIcon className="w-6 h-6 inline mr-2 text-orange-400" />Live Camera: Owner will be able to view your cat anytime & anywhere (one-time activation fee of $10)</li>
              <li><EmojiHappyIcon className="w-6 h-6 inline mr-2 text-orange-400" />24-hour air conditioned</li>
              <li><HeartIcon className="w-6 h-6 inline mr-2 text-orange-400" />30-60 minutes daily play</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="box-border flex flex-col items-center content-center px-4 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl">

        <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
          <h2 className="text-orange-500 m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
            {'Premium Loft (Up to 3 guests from same household)'}
          </h2>
          <div className="flex items-end mt-3 leading-7 text-orange-500 border-0">
            <p className="box-border mr-1">
              SGD
            </p>
            <p className="box-border m-0 text-4xl font-semibold leading-none border-solid">
              70
            </p>
            <p className="box-border m-0 border-solid">
              {'/ night'}
            </p>
          </div>
          <div className='mb-3 pb-4 pt-2'>
            <p className='text-brown'>
              <span className='super'>*</span> {'SGD 20/night for the 2nd Cat, SGD 10/night for the 3rd Cat'}
            </p>
          </div>
          <div>
            <ul className="pl-6 space-y-1 text-brown">
              <li><HomeIcon className="w-6 h-6 inline mr-2 text-orange-400" />Room size: 1.2m x 1.8m x 0.6m</li>
              <li><VideoCameraIcon className="w-6 h-6 inline mr-2 text-orange-400" />Live Camera: Owner will be able to view your cat anytime & anywhere (one-time activation fee of $10)</li>
              <li><EmojiHappyIcon className="w-6 h-6 inline mr-2 text-orange-400" />24-hour air conditioned</li>
              <li><HeartIcon className="w-6 h-6 inline mr-2 text-orange-400" />30-60 minutes daily play</li>
            </ul>
          </div>
        </div>

        <div className="box-border order-first md:order-last relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
          <img src={premierLoft} className="p-2 pl-6 pr-5" />
        </div>
      </div>

      <div className="text-center text-orange-500 mt-10 mb-5">
        <a href="/boarding-guide" className="underline text-xl mr-2">{'Need help on Boarding Guide?'}</a>
      </div>
      <div className="text-center text-orange-500 mb-10">
        <a href="/cat-boarding" className="underline text-xl mr-2">{'Submit Onboarding Registration'}</a>
      </div>

    </section>
  );
}
