import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from '../../mui.theme';
import * as Yup from 'yup';

export default function Step1(props) {
    const [submitted, setSubmitted] = useState();
    const preferredContacts = ['WhatsApp', 'WeChat'];

    const renderPreferredContactInput = (preferredContact) => {
        switch (preferredContact) {
            case 'WeChat':
                return (
                    <TextField
                        fullWidth
                        label="Your WeChat ID"
                        {...getFieldProps('preferredcontact')}
                        error={Boolean(touched.preferredcontact && errors.preferredcontact)}
                    />
                )
                break;
            default:
                break;
        }
    };

    const schema = Yup.object().shape({
        ownername: Yup.string().required('Name is required'),
        ownermobilenumber: Yup.string().required('Contact Number is required'),
        owneraddress: Yup.string().required('Home Address is required'),
        preferredcontactmethod: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        initialValues: Object.assign({}, props.initialValues),
        validationSchema: schema,
        onSubmit: (values, actions) => {
            props.handleNext(values);
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <ThemeProvider theme={muiTheme}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                label="Your Name *"
                                {...getFieldProps('ownername')}
                                error={Boolean(touched.ownername && errors.ownername)}
                            />

                            <TextField
                                fullWidth
                                label="Your Contact Number *"
                                type="tel"
                                {...getFieldProps('ownermobilenumber')}
                                error={Boolean(touched.ownermobilenumber && errors.ownermobilenumber)}
                            />

                            <TextField
                                fullWidth
                                label="Your Home Address *"
                                {...getFieldProps('owneraddress')}
                                error={Boolean(touched.owneraddress && errors.owneraddress)}
                            />

                            <FormControl>
                                <InputLabel id="simple-select-label" error={Boolean(touched.preferredcontactmethod && errors.preferredcontactmethod)}>Prefer us to contact you through *</InputLabel>
                                <Select
                                    labelId="simple-select-label"
                                    id="simple-select"
                                    label="Prefer us to contact you through *"
                                    {...getFieldProps('preferredcontactmethod')}
                                    error={Boolean(touched.preferredcontactmethod && errors.preferredcontactmethod)}
                                >
                                    {
                                        preferredContacts.map(item => (
                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            {renderPreferredContactInput(getFieldProps('preferredcontactmethod').value)}

                            <TextField
                                fullWidth
                                label="Your Email Address (optional)"
                                type="email"
                                {...getFieldProps('email')}
                                error={Boolean(touched.email && errors.email)}
                            />
                            <Button variant="outlined" type="submit">Next</Button>
                        </Stack>
                    </ThemeProvider>
                </Form>
            </FormikProvider>
        </>
    );
}
