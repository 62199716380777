import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import WechatIMG114 from '../../images/WechatIMG114.jpeg';
import WechatIMG115 from '../../images/WechatIMG115.jpeg';
import WechatIMG116 from '../../images/WechatIMG116.jpeg';
import WechatIMG118 from '../../images/WechatIMG118.jpeg';

export default function StandardImageList() {
  return (
    <ImageList sx={{ width: 320, height: 450 }} cols={2} >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={item.img}
            srcSet={item.img}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: WechatIMG114,
    title: 'Cat Boarding from www.catinn.sg',
  },
  {
    img: WechatIMG115,
    title: 'Cat Boarding from www.catinn.sg',
  },
  {
    img: WechatIMG116,
    title: 'Cat Boarding from www.catinn.sg',
  },
  {
    img: WechatIMG118,
    title: 'Cat Boarding from www.catinn.sg',
  }
];