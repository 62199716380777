import CatGroomingPrice from './grooming/cat-grooming-price';

export default function CatGrooming() {
  return(
    <div className="w-5/6 m-auto mb-20 md:w-1/2">
      <h1 className="text-4xl font-bold text-orange-500 text-center mt-5 mb-2">{'Cat Grooming'}</h1>
      <CatGroomingPrice />
      <div className="mt-5 text-sm text-gray-600">
        <p className="underline">{'Grooming Requirements for Cats'}</p>
        <p>{'T & J Cat Inn provides grooming services to cats who are compliant with the following grooming requirements:'}</p>
        <p>{'- The Pet is within the ages of 1 year to 10 years of age'}</p>
        <p>{'- The Pet is free from underlying health conditions;'}</p>
        <p>{'- The Pet does not suffer from any form of physical injury or handicap;'}</p>
        <p>{'- The Pet has received the full schedule of FVRCP vaccinations or a FVRCP booster shot within the past 12 months, OR;'}</p>
        <p>{'- The Pet has undergone a titer test within the past 12 months and possesses proof of sufficient levels of FVRCP antibodies;'}</p>
        <p>{'- The Pet is free of fungal infection and cat parasites, including but not limited to fleas, ticks, mites, ringworm, coccidia and giardia;'}</p>
        <p>{'- The Pet has not bitten or exhibited other aggressive behavior towards any person or animal, except as has been previously disclosed to Company'}</p>
        <p>{'- We may, in our sole discretion, agree to provide services to cats who do not meet our grooming requirements (ie. aggressive, infected with parasites, ailing or convalescing), in the interest of providing treatment or comfort to these cats. However, the owner agrees to waive T & J Cat Inn and our employees from any and all claims, actions, damages or liability for injury, death, sickness or illness suffered by the cat.'}</p>
      </div>
    </div>
  )
}
