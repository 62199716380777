export default function BoardingGuide() {
  return(
    <section className="relative py-16 min-w-screen animation-fade animation-delay">
        <div className="container px-0 px-8 mx-auto sm:px-12 xl:px-5">
            <h3 className="text-2xl text-center font-bold text-gray-800 sm:text-3xl md:text-4xl lg:text-5xl">
                Admission
            </h3>
            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <h3 className="text-lg font-bold text-orange-400 sm:text-xl md:text-2xl">{'Admission for adult cats (above 1 year old)'}</h3>
                <div className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                  <ul className="list-disc pl-6">
                    <li>{'Vaccinated within the past 12 months OR;'}</li>
                    <li>{'In possession of a titer test for proof of vaccine antibodies'}</li>
                    <li>{'Not FIV or FeLV positive'} </li>
                    <li>{'Sterilised if over 8 months of age or showing signs of sexual maturity'}</li>
                    <li>{'Physically free of fleas, ticks, mites, and other parasites'}</li>
                    <li>{'In physically good health for the past 30 days and not exhibiting symptoms of illness OR;'}</li>
                    <li>{'If it has just recovered from an illness, that a written note is provided by a licensed veterinarian declaring the cat to be free of infection'} </li>
                    <li>{'Clean (e.g. Eyes, ears and coat are not dirty) and has had its nails clipped'}</li>
                  </ul>
                </div>
            </div>

            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <h3 className="text-lg font-bold text-orange-400 sm:text-xl md:text-2xl">{'Admission for kitten (below 12 months of age)'}</h3>
                <div className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                  {'We always recommend that clients with kittens complete a full vaccination schedule prior to entering our facilities. This can be done at your local veterinarian when they are of:'}
                  <ul className="list-disc pl-6 pb-6">
                    <li>{'8 weeks of age'}</li>
                    <li>{'12 weeks of age'}</li>
                    <li>{'16 weeks of age'} </li>
                  </ul>
                  <i className="text-md">
                  {'*If your kitten is unable to produce proof of completion of its vaccinations, our caretakers may advise that they are confined in their cabins for the duration of their stay for their own health and safety. Please feel free to call in and discuss any concerns with us prior to make a booking.'}
                  </i>
                </div>
            </div>

            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <h3 className="text-lg font-bold text-orange-400 sm:text-xl md:text-2xl">{'Admission for senior cat (above 12 years old)'}</h3>
                <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                  {'For senior cats whose veterinarians do not recommend vaccination booster shots, we are happy to accept a written note declaring that your cat is suitable for boarding and compliant with our terms of stay.'}
                </p>
            </div>


            <h3 className="mt-16 text-2xl text-center font-bold text-gray-800 sm:text-3xl md:text-4xl lg:text-5xl">
                {'Boarding Checklist'}
            </h3>
            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <div className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                <ul className="list-disc pl-6">
                  <li>{'received vaccination at least 2 weeks prior to checking in'}</li>
                  <li>{'never contracted the FIV, FeLV and/or FVR virus'}</li>
                  <li>{'not been ill, injured or infected with disease in the past 14 days'} </li>
                  <li>{'flea, tick and mite free'} </li>
                  <li>{'been spayed or neutered '} </li>
                  <li>{'not displayed signs of aggression before around strangers'} </li>
                </ul>
                <br/>
                <i className="text-md">{'*If your cat is aggressive or uncomfortable being handled, we reserve the right to allow it to remain in its cabin for the duration of its stay, not just for the wellbeing and safety of your cat, but also for other guests sharing the hotel premises.'}</i>
                </div>
            </div>


            <h3 className="mt-16 text-2xl text-center font-bold text-gray-800 sm:text-3xl md:text-4xl lg:text-5xl">
                {'Things to bring'}
            </h3>
            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <h3 className="text-lg font-bold text-orange-400 sm:text-xl md:text-2xl">{'Feline Guest'}</h3>
                <div className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                <ul className="list-disc pl-6">
                  <li>{'A clean blanket or bed'}</li>
                  <li>{'A favourite toy'}</li>
                  <li>{'A brush'}</li>
                  <li>{'A pipette of Revolution for Cats or Kittens per feline guest'}</li>
                  <li>{'A secure transport crate or carrier lined with sanitary sheets'}</li>
                  <li>{'Individually bagged and labelled meals'}</li>
                </ul>
                </div>
            </div>
            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <h3 className="text-lg font-bold text-orange-400 sm:text-xl md:text-2xl">{'Owner'}</h3>
                <div className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                <ul className="list-disc pl-6">
                  <li>{'Owner identification card'}</li>
                  <li>{'Vaccination card with proof of valid vaccination (ie. should cover the entire duration of boarding) or titer test'}</li>
                  <li>{'FIV/FeLV negative test issued within the past 12 months (if tested)'}</li>
                  <li>{'Sterilization certificate'}</li>
                </ul>
                <br/>
                <i className="text-md">
                  {'*We recommend retrieving your cat’s sterilization and vaccination records before making a reservation with us.  You will also require proof that it is FIV/FeLV negative. All guests at T&J CAT INN are required to be in good health, sterilized, certified to have received their FVRCP vaccine within the past 12 months, and be free from infection, parasites, illness and disease within the past 30 days prior to check-in.'}
                </i>
                </div>
            </div>

            <h3 className="mt-16 text-2xl text-center font-bold text-gray-800 sm:text-3xl md:text-4xl lg:text-5xl">
                {'Check-in and Check-out'}
            </h3>
            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <h3 className="text-lg font-bold text-orange-400 sm:text-xl md:text-2xl">{'Check-in'}</h3>
                <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                {'2pm to 6pm (early check in available, please contact our staff 24hours in advance for early check in arrangement)'}
                </p>
            </div>
            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <h3 className="text-lg font-bold text-orange-400 sm:text-xl md:text-2xl">{'Check-out'}</h3>
                <div className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                <ul className="list-disc pl-6">
                  <li>{'10am to 2pm'}</li>
                  <li>{'Latest check out time is 6pm (after 6pm will be charged an additional day’s boarding rate)'}</li>
                  <li>{'Late check-out fee applicable (S$5/hour)'} </li>
                </ul>
                <br/>
                <i className="text-sm">
                  {'*Please kindly inform our staff your estimated arrival time / check out time 24 hours in advance'}
                </i>
                </div>
            </div>

            <h3 className="mt-16 text-2xl text-center font-bold text-gray-800 sm:text-3xl md:text-4xl lg:text-5xl">
                {'Cancellation and Refund '}
            </h3>
            <div className="w-full px-6 py-6 mx-auto mt-2 md:mt-10 md:bg-white md:border-gray-200 md:border rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                <div className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                {'We offer refunds at our discretion and after deduction of any expenses already incurred by us provided you cancel your booking 7 days before the commencement of the boarding of your pet. However, we have a strict non-refundable, non-cancellation, non-amendable policy in the following situations:'}
                <ul className="list-disc pl-6">
                  <li>{'Reservations made for the months of June, December and Chinese New Year'}</li>
                  <li>{'After boarding your pet and you decide to check out your pet before the expiry of the booked boarding, regardless of the reason'}</li>
                  <li>{'An administrative fee of 20% will be charged for any refund/cancellation'}</li>
                </ul>
                </div>
            </div>

        </div>

        <div className="text-center text-orange-500 my-10">
          <a href="/cat-boarding" className="underline text-xl mr-2">{'Submit Boarding Registration'}</a>
        </div>

    </section>

  )
}
