import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from '../mui.theme';
import * as Yup from 'yup';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
const axios = require('axios').default;

export default function GroomingForm() {
  const [submitted, setSubmitted] = useState();

  const timings = [
    '2:00 PM',
    '3:00 PM',
    '4:00 PM'
  ];

  const breedtypes = [
    'Long Hair',
    'Short Hair'
  ];

  const RegisterSchema = Yup.object().shape({
    customername: Yup.string().required('Customer Name is required'),
    mobilenumber: Yup.string().required('Mobile Number is required'),
    catname: Yup.string().required('is required'),
    catage: Yup.string().required('is required'),
    breed: Yup.string().required('is required'),
    breedtype: Yup.string().required('is required'),
    groomingservice: Yup.string().required('is required'),
    groomingdate: Yup.string().required('is required'),
    preferredtime: Yup.string().required('is required')
  });

  const formik = useFormik({
    initialValues: {
      customername: '',
      mobilenumber: '',
      catname: '',
      catage: '',
      breed: '',
      breedtype: '',
      groomingservice: '',
      addonservice: {},
      groomingdate: '',
      preferredtime: ''
    },

    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      let addonservice = [];
      if(values.removeundercoat && values.removeundercoat.length > 0) {
        addonservice.push('Remove undercoat');
      }
      if(values.dematting && values.dematting.length > 0) {
        addonservice.push('De matting');
      }
      const api = process.env.REACT_APP_API;
      axios.post(`${api}/grooming-registrations`, {
        customername: values.customername,
        mobilenumber: values.mobilenumber,
        catname: values.catname,
        catage: values.catage,
        breed: `${values.breed} ${values.breedtype}`,
        groomingservice: values.groomingservice,
        addonservice: addonservice.join(','),
        groomingdate: values.groomingdate.toDateString(),
        preferredtime: values.preferredtime,
      }).then(function (response) {
        actions.resetForm();
        setSubmitted(true);
      }).catch(function () {});
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {submitted
        ?
        <div className="relative w-full mt-10 space-y-8">
          <h5 className="text-brown h-96 w-full text-xl">{'We have received your grooming registration. We will be in touch with you shortly via WhatsApp or phone call. Thank you!'}</h5>
        </div>
        :
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <ThemeProvider theme={muiTheme}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Customer Name *"
                    {...getFieldProps('customername')}
                    error={Boolean(touched.customername && errors.customername)}
                  />

                  <TextField
                    fullWidth
                    label="Mobile Number *"
                    type="tel"
                    {...getFieldProps('mobilenumber')}
                    error={Boolean(touched.mobilenumber && errors.mobilenumber)}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Cat's Name *"
                    {...getFieldProps('catname')}
                    error={Boolean(touched.catname && errors.catname)}
                  />

                  <TextField
                    fullWidth
                    label="Cat's Age *"
                    {...getFieldProps('catage')}
                    error={Boolean(touched.catage && errors.catage)}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Breed *"
                    {...getFieldProps('breed')}
                    error={Boolean(touched.breed && errors.breed)}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="breed-simple-select-label" error={Boolean(touched.breedtype && errors.breedtype)}>Hair *</InputLabel>
                    <Select
                      labelId="breed-simple-select-label"
                      id="breed-simple-select"
                      label="No. of Onboarding Guests *"
                      {...getFieldProps('breedtype')}
                      error={Boolean(touched.breedtype && errors.breedtype)}
                    >
                      {
                        breedtypes.map(item => (
                          <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Stack>

                <FormControl>
                  <FormLabel id="grooming-radio-buttons-group" sx={{ fontSize: 14 }} error={Boolean(touched.groomingservice && errors.groomingservice)}>Grooming Service *</FormLabel>
                  <RadioGroup
                    aria-labelledby="grooming-radio-buttons-group"
                    name="grooming-radio-buttons-group"
                    {...getFieldProps('groomingservice')}
                    sx={{ fontSize: 8 }}
                  >
                    <FormControlLabel value="Basic Grooming" control={<Radio />} label="Basic Grooming" />
                    <FormLabel sx={{ fontSize: 14, display: 'block' }}>$70 for short hair cat; $80 for long hair cat<br /></FormLabel>
                    <FormControlLabel value="Full Grooming A (Fur trimming)" control={<Radio />} label="Full Grooming A (Fur trimming)" />
                    <FormLabel sx={{ fontSize: 14, display: 'block' }}>$120 for short hair cat; $140 for long hair cat<br /></FormLabel>
                    <FormControlLabel value="Full Grooming B (Fur trimming + Cut & style)" control={<Radio />} label="Full Grooming B (Fur trimming + Cut & style)" />
                    <FormLabel sx={{ fontSize: 14, display: 'block' }}>$140 for short hair cat; $160 for long hair cat<br /></FormLabel>
                  </RadioGroup>
                </FormControl>

                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                  <FormLabel component="legend">Add-on Services</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox {...getFieldProps()} name="removeundercoat" />
                      }
                      label="Remove undercoat ($20)"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox {...getFieldProps()} name="dematting" />
                      }
                      label="De matting ($20)"
                    />
                  </FormGroup>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <MobileDatePicker
                      label="Preferred Date *"
                      name="groomingdate"
                      closeOnSelect={true}
                      disablePast={true}
                      minDate={new Date().setDate((new Date()).getDate() + 1)}
                      value={formik.values.groomingdate}
                      onChange={(value) => formik.setFieldValue('groomingdate', value)}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} error={Boolean(touched.groomingdate && errors.groomingdate)} />}
                    />

                    <FormControl fullWidth>
                      <InputLabel id="timing-simple-select-label" error={Boolean(touched.preferredtime && errors.preferredtime)}>Preferred Time *</InputLabel>
                      <Select
                        labelId="timing-simple-select-label"
                        id="timing-simple-select"
                        label="Preferred Time *"
                        {...getFieldProps('preferredtime')}
                        error={Boolean(touched.preferredtime && errors.preferredtime)}
                      >
                        {
                          timings.map(item => (<MenuItem key={item} value={item}>{item}</MenuItem>))
                        }
                      </Select>
                    </FormControl>
                  </Stack>
                </LocalizationProvider>

                <Button variant="outlined" type="submit">Submit</Button>

              </Stack>
            </ThemeProvider>
          </Form>
        </FormikProvider>
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
