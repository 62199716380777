import { useState } from 'react'
import catBoardingImage from '../images/cat-boarding-home.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import StandardImageList from './home/ImageList';

export default function Home() {
  return(
    <>
    <Swiper
        spaceBetween={0}
        autoplay={{
          delay: 4000
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="w-full h-3/5 md:h-4/5"
      >

        <SwiperSlide className="flex bg-cover bg-center bg-cover2 md:bg-center">
          <div className="absolute bottom-8 left-10 right-10 rounded-lg bg-white/60 md:w-1/2 md:mx-auto">
            <h1 className='text-lg font-medium text-brown py-2 px-4'>Visit Our Brand-new Outlet with VR Today</h1>
            <a href="/vr" className="underline pb-3 block text-center text-brown">{'Explore Now'}</a>
          </div>
        </SwiperSlide>

        <SwiperSlide className="flex bg-cover bg-center bg-cover4">
          <div className="absolute bottom-8 left-10 right-10 rounded-lg bg-white/60 md:w-1/2 md:mx-auto">
            <h1 className='text-lg font-medium text-brown py-2 px-4'>We Offer a Variety of Room Types for Your Cats</h1>
            <a href="/room-types" className="underline pb-3 block text-center text-brown">{'Explore  Now'}</a>
          </div>
        </SwiperSlide>

        <SwiperSlide className="flex bg-cover bg-center bg-catinnfb1">
        </SwiperSlide>
        
        <SwiperSlide className="flex bg-cover bg-center bg-catinnfb2">
        </SwiperSlide>

        <SwiperSlide className="flex bg-cover bg-center bg-catinnfb3">
        </SwiperSlide>

      </Swiper>

    <h1 className="text-xl font-medium text-orange-500 text-center pt-5 mx-10">{'Exclusive Cat-Only Boarding Services'}</h1>

    <div className="w-5/6 m-auto mb-20">
      <div className="text-medium py-10 text-brown">
        {'T&J Cat Inn is run by passionate cat lovers. We believe that our feline friends deserve the better. All our staff are huge cat lovers and we spend quality time with your kitty throughout the day to give them individual attention! '}
        <br/>
        <br/>
        {'Our brand-new facility has everything your fur baby could ever want and more!'}
      </div>

      {/*}
      <img alt="Cat Boarding Singapore from catinn.sg" className="m-auto w-full md:w-1/2 rounded-2xl" src={catBoardingImage} />
      */}
      <div className="text-center text-brown mt-4 mb-10 space-y-2 md:w-1/3 mx-auto">
        <a href="/book-viewing" className="flex items-center justify-between w-full px-5 py-2 text-xl font-medium text-center text-brown border-2 border-orange-400 border-solid rounded-lg ease">{'Book Private Viewing'}</a>
        <a href="/cat-boarding" className="flex items-center justify-between w-full px-5 py-2 text-xl font-medium text-center text-brown border-2 border-orange-400 border-solid rounded-lg ease">{'Boarding Registration'}</a>
      </div>   
      <div className="text-center text-brown mt-4 mb-10 space-y-2 mx-auto flex justify-center">
        <StandardImageList />   
      </div>
      
      {/*
      <h1 className="text-4xl font-bold text-orange-500 text-center mt-5 mb-2">{'Cat Grooming'}</h1>
      <CatGroomingPrice />
      <div className="text-center text-brown mt-2 mb-10">
        <a href="https://wa.me/+6587217587" target="_blank" className="underline text-xl mr-2">{'Make an appointment'}</a>
      </div>
      */}

    </div>
    </>
  )
}
