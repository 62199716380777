import SGQR from '../documents/sgqr.jpg'

export default function ContactUs() {
  return(
    <div className="w-5/6 m-auto mb-20">
      <h1 className="text-4xl font-bold text-orange-500 text-center mt-5 mb-2">{'Payment Mode'}</h1>
      <div className="text-gray-500 my-10">
        <h2 className="font-bold text-2xl">{'1. Bank Transfer'}</h2>
        <div className="flex flex-row">
          <div className="pr-2">
            <div>{'Bank Name:'}</div>
            <div>{'Name:'}</div>
            <div>{'Account No:'}</div>
          </div>
          <div>
            <div className="font-bold">{'DBS'}</div>
            <div className="font-bold">{'T&J CAT LOVER PTE. LTD.'}</div>
            <div className="font-bold">{'0726535380'}</div>
          </div>
        </div>
      </div>

      <div className="text-gray-500 my-10">
        <h2 className="font-bold text-2xl">{'2. PayNow'}</h2>
        <img className="rounded-3xl" src={SGQR} />
      </div>

    </div>
  )
}
