import { createTheme } from '@mui/material/styles';
export default createTheme({
    palette: {
        primary: {
            main: '#f97516',
        },        
        secondary: {
            main: '#551d03',
        },
    },  
});