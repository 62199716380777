import ViewingForm from '../../forms/ViewingForm';


export default function BookViewing() {
   return (
     <div className="w-5/6 m-auto mb-20 md:w-1/2">
       <h1 className="text-2xl font-medium text-orange-500 text-center mt-5 mb-5">{'Book Private Viewing'}</h1>
       <a href="/vr" className="underline mb-5 block text-center text-brown">{'Visit us with virtual tour'}</a>
       <ViewingForm />
     </div>
   );
 }
