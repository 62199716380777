import { useState } from 'react'
import { ArrowCircleRightIcon } from '@heroicons/react/outline';
import catBoardingImage from '../images/cat-boarding-home.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

export default function Cover() {
  return(
    <>

    <Swiper
        spaceBetween={0}
        autoplay={{
          delay: 4000
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="w-full h-3/4"
      >
        <SwiperSlide className="flex bg-cover bg-center bg-right bg-cover1">
          <div className="w-5/6 m-auto rounded-2xl bg-white/40">
            <h1 className="text-4xl font-bold text-orange-500 text-center pt-5 mb-2">{'Exclusive CATS ONLY Boarding and Grooming'}</h1>
            <div className="text-center text-gray-500 mb-10">
              <a href="tel:+6587217587" className="underline text-xl text-gray-600">{'Call us to arrange a viewing'}</a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="flex bg-contain bg-center bg-no-repeat bg-cover4">
          <div className="w-5/6 m-auto rounded-2xl bg-white/40">
          </div>
        </SwiperSlide>
      </Swiper>

    <div className="w-5/6 m-auto mb-20">
      <div className="text-xl py-10 text-gray-600">
        {'T&J Cat Inn is run by passionate cat lovers. We believe that our feline friends deserve the better. All our staff are huge cat lovers and we spend quality time with your kitty throughout the day to give them individual attention! '}
        <br/>
        <br/>
        {'Our brand-new facility has everything your fur baby could ever want and more!'}

      </div>


      <img className="m-auto w-full md:w-1/2 rounded-2xl" src={catBoardingImage} />
      <div className="text-center text-gray-500 mt-2 mb-10">
        <a href="/room-types" className="underline text-xl mr-2">{'Find out more'}</a>
        <ArrowCircleRightIcon className="inline w-5 h-5 text-orange-400" />
      </div>

    </div>

    </>
  )
}
