import { PhoneIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import { MapIcon, ChatIcon } from '@heroicons/react/outline'

export default function ContactUs() {
  return(
    <section id="contactus" className="px-2 py-10 md:py-20 md:px-0">
      <div className="container items-center max-w-6xl px-5 md:px-8 mx-auto xl:px-5">
        <div className="flex flex-wrap items-center sm:-mx-3">
          <div className="w-full md:w-1/2 md:px-3">
            <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                <span className="block text-orange-500 xl:inline">Locate Us</span>
              </h1>
              <div className="relative flex flex-col">

              <div className="flex flex-row items-center my-5 text-xl">
                  <PhoneIcon className="h-6 w-6 text-orange-500" />
                  <span className="pl-5"><a href="tel:84275351" target="_blank" className="text-gray-500">{'8427 5351 (Call Only)'}</a></span>
                </div>

                <div className="flex flex-row items-center my-5 text-xl">
                  <ChatIcon className="h-6 w-6 text-orange-500" />
                  <span className="pl-5"><a href="https://wa.me/+6587217587" target="_blank" className="text-gray-500">{'8721 7587 (WhatsApp Only)'}</a></span>
                </div>

                <div className="flex flex-row items-center my-5 text-xl">
                  <MapIcon className="h-6 w-6 text-orange-500" />
                  <div className="pl-5">
                    <div className="text-gray-500">{
                      '106A Desker Rd, #02-00, Singapore 209628'
                    }</div>
                    <a target="_blank" className="underline text-gray-500" href="https://www.google.com/maps/dir//106+Desker+Rd+Singapore+209628/@1.3082672,103.8560838,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x31da19c7fc98bf43:0x1849382c406fd866!2m2!1d103.8560838!2d1.3082672">{'Get Directions'}</a>
                  </div>
                </div>

                <div className="text-gray-500">{
                  'Operating Hours: Monday to Sunday (9AM - 2PM, 3PM - 8PM)'
                }</div>

              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
              <iframe width="100%" height="400px" id="gmap_canvas" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.112610094046!2d103.8561441!3d1.3083448!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19aa9ca533ef%3A0xa0a3e64d28d39bc7!2sT%26J%20Cat%20Inn!5e0!3m2!1sen!2ssg!4v1713780234279!5m2!1sen!2ssg">
              </iframe>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
