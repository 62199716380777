import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from '../../mui.theme';
import * as Yup from 'yup';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';

const schema = Yup.object().shape({
    catname: Yup.string().required('is required'),
    catagebreed: Yup.string().required('is required'),
    catgender: Yup.string().required('is required'),
    catsterilized: Yup.string().required('is required'),
    catvaccidate: Yup.string().required('is required'),
    catcoughingsneezing: Yup.string().required('is required')
});

export default function Step1(props) {
    const [addingAnother, setAddingAnother] = useState(false);

    const initialValues = props.initialValues[0];

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, actions) => {
            // console.log(values);
            props.handleNext(values, addingAnother);
            actions.resetForm();
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, submitForm } = formik;

    const renderCatDetails = (initialValues) => {
        if (initialValues.length > 1) {
            return (
                initialValues.slice(1).reverse().map((item, index, arr) => (
                    <div className='flex col' key={index}>
                        <div className='flex-1'>Cat: {item.catname}</div>
                        <Link
                            component="button"
                            type="button"
                            variant="body2"
                            onClick={() => {
                                props.handleRemove(arr.length - index);
                            }}
                        >
                            {'Discard'}
                        </Link>
                    </div>
                ))
            );
        }
    }

    return (
        <>
            {renderCatDetails(props.initialValues)}

            {props.initialValues.length > 1 &&
                <div className='flex col'>
                    <div className='flex-1'>Cat:</div>
                    <Link
                        component="button"
                        type="button"
                        variant="body2"
                        onClick={() => {
                            props.handleRemove(0);
                        }}
                    >
                        {'Discard'}
                    </Link>
                </div>
            }

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <ThemeProvider theme={muiTheme}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                label="Your Cat's Name *"
                                {...getFieldProps('catname')}
                                error={Boolean(touched.catname && errors.catname)}
                            />

                            <TextField
                                fullWidth
                                label="Your Cat's Age & Breed *"
                                {...getFieldProps('catagebreed')}
                                error={Boolean(touched.catagebreed && errors.catagebreed)}
                            />

                            <FormControl>
                                <FormLabel id="gender-radio-buttons-group" sx={{ fontSize: 12 }} error={Boolean(touched.catgender && errors.catgender)}>Your Cat's Gender *</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="gender-radio-buttons-group"
                                    name="gender-radio-buttons-group"
                                    {...getFieldProps('catgender')}
                                >
                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                </RadioGroup>
                            </FormControl>

                            <FormControl>
                                <FormLabel id="catsterilized-radio-buttons-group" sx={{ fontSize: 12 }} error={Boolean(touched.catsterilized && errors.catsterilized)}>{'Is Your Cat Sterilized? *'}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="catsterilized-radio-buttons-group"
                                    name="catsterilized-radio-buttons-group"
                                    {...getFieldProps('catsterilized')}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div>
                                <MobileDatePicker
                                    label="Last Annual Vaccination *"
                                    name="catvaccidate"
                                    closeOnSelect={true}
                                    disableFuture={true}
                                    value={formik.values.catvaccidate}
                                    onChange={(value) => formik.setFieldValue('catvaccidate', value)}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField fullWidth {...params} error={Boolean(touched.catvaccidate && errors.catvaccidate)} />}               
                                />
                                <FormLabel sx={{ fontSize: 12 }}>* All Cats must be up to date on vaccinations.<br/></FormLabel>
                                <FormLabel sx={{ fontSize: 12 }}>* Picture of Vaccination record you can email us at <a href="mailto:admin@catinn.sg" className="underline">admin@catinn.sg</a> or WhatsApp to <a className="underline" href="https://wa.me/+6587217587" target="_blank">+65 8721-7587</a> after your registration is confirmed. You can also bring the Vaccination record on the same day of check-in.</FormLabel>
                                </div>
                            </LocalizationProvider>

                            <TextField
                                fullWidth
                                label="Feeding Instructions"
                                {...getFieldProps('feeding')}
                            />     

                            <FormControl>
                                <FormLabel id="catcoughingsneezing-radio-buttons-group" sx={{ fontSize: 12 }} error={Boolean(touched.catcoughingsneezing && errors.catcoughingsneezing)}>{'Has Your Cat had any coughing/sneezing in the past 2 weeks? *'}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="catcoughingsneezing-radio-buttons-group"
                                    name="catcoughingsneezing-radio-buttons-group"
                                    {...getFieldProps('catcoughingsneezing')}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>

                                <Link
                                    component="button"
                                    type="button"
                                    variant="body2"
                                    onClick={() => {
                                        setAddingAnother(true);
                                        submitForm();
                                    }}
                                >
                                    Add Another Cat Details
                                </Link>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={props.handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button variant="outlined" type="button" onClick={() => {
                                    setAddingAnother(false);
                                    submitForm();
                                }}>Next</Button>
                            </Box>
                        </Stack>
                    </ThemeProvider>
                </Form>
            </FormikProvider>

        </>
    );
}
