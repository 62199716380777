import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from '../mui.theme';
import * as Yup from 'yup';
const axios = require('axios').default;


export default function ViewingForm() {
  const [submitted, setSubmitted] = useState();

  const timings = [
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM'
  ];
  const guests = ['1', '2', '3', '4', '5'];
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    contact: Yup.string().required('Contact Number is required'),
    datepreferred: Yup.string().required('Date is required'),
    timingpreferred: Yup.string().required('is required'),
    intendedcheckindate: Yup.string().required('is required'),
    intendedcheckoutdate: Yup.string().required('is required'),
    numberofguests: Yup.string().required('is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      contact: '',
      datepreferred: null,
      timingpreferred: '',
      intendedcheckindate: null,
      intendedcheckoutdate: null,
      numberofguests: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      const api = process.env.REACT_APP_API;
      axios.post(`${api}/viewings`, {
        name: values.name,
        contact: values.contact,
        datepreferred: values.datepreferred.toDateString(),
        timingpreferred: values.timingpreferred,
        intendedcheckindate: values.intendedcheckindate.toDateString(),
        intendedcheckoutdate: values.intendedcheckoutdate.toDateString(),
        numberofguests: values.numberofguests
      }).then(function (response) {
        actions.resetForm();
        setSubmitted(true);
      }).catch(function () {});
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
    {submitted
      ? 
      <div className="relative w-full mt-10 space-y-8">
        <h5 className="text-brown h-96 w-full text-xl">{'We have received your private viewing booking. We will be in touch with you shortly via WhatsApp or phone call. Thank you!'}</h5>          
      </div>
      :
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <ThemeProvider theme={muiTheme}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Your Name *"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
              />

              <TextField
                fullWidth
                label="Your Contact Number *"
                type="tel"
                {...getFieldProps('contact')}
                error={Boolean(touched.contact && errors.contact)}
              />
            </Stack>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <MobileDatePicker
                  label="Preferred Viewing Date *"
                  name="datepreferred"
                  closeOnSelect={true}
                  disablePast={true}
                  minDate={new Date().setDate((new Date()).getDate() + 1)}
                  value={formik.values.datepreferred}
                  onChange={(value) => formik.setFieldValue('datepreferred', value)}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} error={Boolean(touched.datepreferred && errors.datepreferred)}/>}
                />

                <FormControl fullWidth>
                  <InputLabel id="timing-simple-select-label" error={Boolean(touched.timingpreferred && errors.timingpreferred)}>Preferred Timing *</InputLabel>
                  <Select
                    labelId="timing-simple-select-label"
                    id="timing-simple-select"
                    label="Preferred Timing *"
                    {...getFieldProps('timingpreferred')}
                    error={Boolean(touched.timingpreferred && errors.timingpreferred)}
                  > 
                    {
                      timings.map(item => (<MenuItem key={item} value={item}>{item}</MenuItem>))
                    }
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <MobileDatePicker
                    label="Intended Check-in Date *"
                    name="intendedcheckindate"
                    closeOnSelect={true}
                    disablePast={true}
                    minDate={new Date().setDate((new Date()).getDate() + 1)}
                    value={formik.values.intendedcheckindate}
                    onChange={
                      (value) => {
                        formik.setFieldValue('intendedcheckindate', value);
                        if(formik.values.intendedcheckoutdate && formik.values.intendedcheckoutdate.getTime() < value.getTime()) {
                          formik.setFieldValue('intendedcheckoutdate', '');
                        }                        
                      }
                    }
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField fullWidth {...params} error={Boolean(touched.intendedcheckindate && errors.intendedcheckindate)} />}
                  />

                <MobileDatePicker
                  label="Intended Check-out Date *"
                  name="intendedcheckoutdate"
                  closeOnSelect={true}
                  disablePast={true}
                  minDate={formik.values.intendedcheckindate}
                  value={formik.values.intendedcheckoutdate}
                  onChange={(value) => formik.setFieldValue('intendedcheckoutdate', value)}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} error={Boolean(touched.intendedcheckoutdate && errors.intendedcheckoutdate)} />}
                />
              </Stack>
            </LocalizationProvider>

            <FormControl>
              <InputLabel id="guests-simple-select-label" error={Boolean(touched.numberofguests && errors.numberofguests)}>No. of Onboarding Guests *</InputLabel>
              <Select
                labelId="guests-simple-select-label"
                id="guests-simple-select"
                label="No. of Onboarding Guests *"
                {...getFieldProps('numberofguests')}
                error={Boolean(touched.numberofguests && errors.numberofguests)}
              >
              {
                guests.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))
              }
              </Select>
            </FormControl>

            <Button variant="outlined" type="submit">Submit</Button>
            
          </Stack>
          </ThemeProvider>
        </Form>
      </FormikProvider>
    }
    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSubmitting}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
