import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import NavMenu from './NavMenu';
import Home from './views/Home';
import Faq from './views/Faq';
import BoardingGuide from './views/boarding/BoardingGuide';
import RoomTypes from './views/boarding/RoomTypes';
import BookViewing from './views/boarding/BookViewing';
import ContactUs from './views/ContactUs';
import CatGrooming from './views/CatGrooming';
import Cover from './views/Cover';
import Payment from './views/Payment';
import VR from './views/VR';
import OnboardingRegistration from './views/boarding/OnboardingRegistration';
import GroomingRegistration from './views/grooming/Registration';
import './index.css';

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <section className="h-screen w-full">
    <NavMenu />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cat-boarding" element={<OnboardingRegistration />} />
      <Route path="/room-types" element={<RoomTypes />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/boarding-guide" element={<BoardingGuide />} />
      <Route path="/boarding-registration" element={<OnboardingRegistration />} />
      <Route path="/locate-us" element={<ContactUs />} />
      <Route path="/cat-grooming" element={<CatGrooming />} />
      <Route path="/grooming-registration" element={<GroomingRegistration />} />
      <Route path="/cover" element={<Cover />} />
      <Route path="/book-viewing" element={<BookViewing />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/vr" element={<VR />} />
    </Routes>
    <div className="py-10">
      <p className="text-base text-xs md:text-sm text-center text-gray-400">
          © 2022 T&J Cat Lover Pte. Ltd. All rights reserved.
      </p>
    </div>
  </section>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
