import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Step1 from './boarding/Step1';
import Step2 from './boarding/Step2';
import Step3 from './boarding/Step3';

const axios = require('axios').default;
const steps = ['Owner Details', 'Cat Details', 'Booking Details'];

export default function BoardingForm() {
    const [ownerDetails, setOwnerDetails] = React.useState({
        ownername: '',
        ownermobilenumber: '',
        owneraddress: '',
        email: '',
        preferredcontactmethod: '',
        preferredcontact: ''
    });

    const [catDetails, setCatDetails] = React.useState([
        {
            catname: '',
            catagebreed: '',
            catgender: '',
            catsterilized: '',
            catcoughingsneezing: '',
            catvaccidate: '',
            feeding: ''
        }
    ]);

    const [bookingDetails, setBookingDetails] = React.useState({
        roomtype: '',
        checkindate: '',
        checkintiming: '',
        checkoutdate: '',
        checkouttiming: '',
        fleatickprotection: '',
        disposablescratchpad: '',
        cameraaccess: '',
        remarks: '',
        agreetermone: '',
        agreetermtwo: ''
    });

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(0);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const renderStep = step => {
        switch (step) {
            case 0:
                return <Step1 handleNext={handleOwnerDetails} initialValues={ownerDetails} />
            case 1:
                return <Step2 handleNext={handleCatDetails} handleBack={handleBack} handleRemove={handleRemoveCat} initialValues={catDetails} />
            case 2:
                return <Step3 handleNext={handleBookDetails} handleBack={handleBack} initialValues={bookingDetails} />
            default:
                break;
        }
    };

    const handleOwnerDetails = (values) => {
        setActiveStep(activeStep + 1);
        setOwnerDetails(values);
        window.scrollTo(0, 0);
    };

    const handleCatDetails = (values, addingAnother) => {
        if (addingAnother) {
            setCatDetails([{
                catname: '',
                catagebreed: '',
                catgender: '',
                catsterilized: '',
                catcoughingsneezing: '',
                catvaccidate: '',
                feeding: ''
            }, { ...values }, ...catDetails.slice(1).reverse()]);
        } else {
            setActiveStep(activeStep + 1);
            setCatDetails([{ ...values }, ...catDetails.slice(1).reverse()]);
        }
        window.scrollTo(0, 0);
    };

    const handleBookDetails = bookingDetails => {
        const calDays = (from, to) => {
            return (from.getTime() - to.getTime()) / (1000*60*60*24);
        };

        const values = Object.assign({},
            bookingDetails,
            ownerDetails,
            { checkindate: bookingDetails.checkindate.toDateString(), checkoutdate: `${bookingDetails.checkoutdate.toDateString()} | ${calDays(bookingDetails.checkoutdate, bookingDetails.checkindate)} nights` },
            catDetails.reduce((pre, cur, index) => {
                if (index == 0) {
                    return Object.assign(pre, cur, {catvaccidate: cur.catvaccidate.toLocaleDateString()});
                }
                return Object.keys(pre).reduce((obj, key) => {
                    const kv = {};
                    if(key == 'catvaccidate') {
                        kv[key] = `${cur[key].toLocaleDateString()} | ${pre[key]}`;
                    } else {
                        kv[key] = `${cur[key]} | ${pre[key]}`;
                    }
                    return Object.assign(obj, kv);
                }, {})
            }, {}));

        setIsSubmitting(true);
        const api = process.env.REACT_APP_API;
        axios.post(`${api}/registrations`, values)
            .then(function (response) {
            })
            .catch(function (error) {
                setIsError(true);
            }).finally(() => {
                setIsSubmitted(true);
                setIsSubmitting(false);
            });
    };

    const handleRemoveCat = index => {
        setCatDetails([...catDetails.slice(0, index), ...catDetails.slice(index + 1)]);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo(0, 0);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {
                isSubmitted ?
                    <div className="relative w-full mt-10 space-y-8">
                        {
                            isError ?
                                <Alert severity="error">
                                    <AlertTitle>Oops!</AlertTitle>
                                    Something went wrong. You may try again later or WhatsApp us directly <a href="https://wa.me/+6587217587" target="_blank" className='underline'>+65 87217587</a>. We apologize for any inconvenience.
                                </Alert>
                                :
                                <Alert severity="success">
                                    <AlertTitle>All Done!</AlertTitle>
                                    {'We have received your boarding registration. We will be in touch with you shortly (via WhatsApp/WeChat or phone call). Thank you!'}
                                </Alert>
                        }
                    </div>
                    :
                    <>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            <React.Fragment>
                                <div className='space-y-5 pt-5'>
                                    {renderStep(activeStep)}
                                </div>
                            </React.Fragment>
                        </div>
                        <Backdrop
                            sx={{ color: '#f97316', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isSubmitting}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </>
            }
        </Box>
    );
}
