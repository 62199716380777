export default function CatGroomingPrice() {
  return(
    <>
      <div className="text-brown flex flex-col rounded-t-xl border-orange-400 border-solid border-x-2 border-t-2">
        <div className="text-lg flex flex-row bg-orange-300 text-white rounded-t-xl">
          <div className="flex-1 p-1">{'Basic Grooming'}</div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1 p-1 border-r border-solid border-orange-400">
            <p>{'- Nail clipping'}</p>
            <p>{'- Ear cleaning'}</p>
            <p>{'- Paw pad shave'}</p>
            <p>{'- Sanitary area shave'}</p>
            <p>{'- Facial cleanse'}</p>
            <p>{'- Wet or dry bath'}</p>
          </div>
          <div className="flex-1 p-1 flex flex-col justify-center">
            <p>{'$70 - Short Hair'}</p>
            <p>{'$80 - Long Hair'}</p>
          </div>
        </div>
      </div>

      <div className="text-brown flex flex-col border-orange-400 border-solid border-x-2">
        <div className="text-lg flex flex-row bg-orange-400 text-white">
          <div className="flex-1 p-1">{'Full Grooming A'}</div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1 p-1 border-r border-solid border-orange-400">
            <p>{'- Nail clipping'}</p>
            <p>{'- Ear cleaning'}</p>
            <p>{'- Paw pad shave'}</p>
            <p>{'- Sanitary area shave'}</p>
            <p>{'- Wet or dry bath'}</p>
            <p>{'- Fur trimming'}</p>
          </div>
          <div className="flex-1 p-1 flex flex-col justify-center">
            <p>{'$120 - Short Hair'}</p>
            <p>{'$140 - Long Hair'}</p>
          </div>
        </div>
      </div>

      <div className="text-brown flex flex-col border-orange-400 border-solid border-b-2 border-x-2 rounded-b-xl">
        <div className="text-lg flex flex-row bg-orange-500 text-white">
          <div className="flex-1 p-1">{'Full Grooming B'}</div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1 p-1 border-r border-solid border-orange-400">
            <p>{'- Nail clipping'}</p>
            <p>{'- Ear cleaning'}</p>
            <p>{'- Paw pad shave'}</p>
            <p>{'- Sanitary area shave'}</p>
            <p>{'- Wet or dry bath'}</p>
            <p>{'- Fur trimming'}</p>
            <p>{'- Cut & style'}</p>
          </div>
          <div className="flex-1 p-1 flex flex-col justify-center">
            <p>{'$140 - Short Hair'}</p>
            <p>{'$160 - Long Hair'}</p>
          </div>
        </div>
      </div>

      <div className="text-sm text-gray-600">
        <p>{'* Short Hair (e.g., Domestic cats/Siamese/Burmese etc)'}</p>
        <p>{'* Long Hair (e.g., Persians/Ragdolls etc)'}</p>
      </div>

      <div className="text-brown mt-5 flex flex-col border-orange-400 border-solid border-b-2 border-2 rounded-xl">
        <div className="text-lg flex flex-row bg-orange-500 text-white rounded-t-xl">
          <div className="flex-1 p-1">{'Add-on Service for Cat Grooming'}</div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1 p-1 border-r border-b border-solid border-orange-400">
            <p>{'Undercoat Removal'}</p>
          </div>
          <div className="flex-1 p-1 flex flex-col justify-center border-b border-solid border-orange-400">
            <p>{'$10 - $80'}</p>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1 p-1 border-r border-solid border-orange-400">
            <p>{'De-matting'}</p>
          </div>
          <div className="flex-1 p-1 flex flex-col justify-center">
            <p>{'$10 - $80'}</p>
          </div>
        </div>
      </div>

    </>
  )
}
