import GroomingForm from '../../forms/GroomingForm';

export default function Registration() {
   return (
     <div className="w-5/6 m-auto mb-20 md:w-1/2">
       <h1 className="text-2xl font-medium text-orange-500 text-center mt-5 mb-5">{'Grooming Registration'}</h1>
       <GroomingForm />
     </div>
   );
 }
