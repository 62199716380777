import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from '../../mui.theme';
import * as Yup from 'yup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import TermsAndConditions from '../../documents/terms-and-conditions.pdf';

export default function Step3(props) {
    const timings = [
        '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM',
        '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
        '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM'
    ];

    const schema = Yup.object().shape({
        roomtype: Yup.string().required('is required'),
        checkindate: Yup.string().required('is required'),
        checkintiming: Yup.string().required('is required'),
        checkoutdate: Yup.string().required('is required'),
        checkouttiming: Yup.string().required('is required'),
        fleatickprotection: Yup.string().required('is required'),
        disposablescratchpad: Yup.string().required('is required'),
        cameraaccess: Yup.string().required('is required'),
        agreetermone: Yup.boolean().required('is required').oneOf([true]),
        agreetermtwo: Yup.boolean().required('is required').oneOf([true])
    });

    const formik = useFormik({
        initialValues: Object.assign({}, props.initialValues),
        validationSchema: schema,
        onSubmit: (values, actions) => {
            props.handleNext(values);
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, submitForm, getFieldProps } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <ThemeProvider theme={muiTheme}>
                        <Stack spacing={3}>
                            <FormControl>
                                <FormLabel id="roomtype-radio-buttons-group" sx={{ fontSize: 12 }} error={Boolean(touched.roomtype && errors.roomtype)}>Choose Room Type *</FormLabel>
                                <RadioGroup
                                    aria-labelledby="roomtype-radio-buttons-group"
                                    name="roomtype-radio-buttons-group"
                                    {...getFieldProps('roomtype')}
                                    sx={{ fontSize: 8 }}
                                >
                                    <FormControlLabel value="Classic Cabin" control={<Radio />} label="Classic Cabin - SGD 42 / night" />
                                    <FormLabel sx={{ fontSize: 12, display: 'block' }}> Classic Cabin open for kittens or cats under 3kg only<br /></FormLabel>
                                    <FormControlLabel value="Premium Classic" control={<Radio />} label="Premium Classic - SGD 52 / night" />
                                    <FormControlLabel value="Deluxe Cabin" control={<Radio />} label="Deluxe Cabin - SGD 55 / night" />
                                    <FormControlLabel value="Premium Suite" control={<Radio />} label="Premium Suite - SGD 62 / night" />
                                    <FormLabel sx={{ fontSize: 12, display: 'block' }}>SGD 20 / night for the 2nd Cat<br /></FormLabel>
                                    <FormControlLabel value="Premium Loft" control={<Radio />} label="Premium Loft - SGD 70 / night" />
                                    <FormLabel sx={{ fontSize: 12, display: 'block' }}>SGD 20 / night for the 2nd Cat; SGD 10 / night for the 3rd Cat<br /></FormLabel>
                                </RadioGroup>
                            </FormControl>

                            <div>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            label="Check-in Date *"
                                            name="checkindate"
                                            closeOnSelect={true}
                                            disablePast={true}
                                            value={formik.values.checkindate}
                                            onChange={
                                                (value) => {
                                                    formik.setFieldValue('checkindate', value);
                                                    if(formik.values.checkoutdate && formik.values.checkoutdate.getTime() < value.getTime()) {
                                                        formik.setFieldValue('checkoutdate', '');
                                                    }
                                                }
                                            }
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => <TextField fullWidth {...params} error={Boolean(touched.checkindate && errors.checkindate)} />}
                                        />
                                    </LocalizationProvider>
                                    <Box sx={{ px: 1 }} />

                                    <FormControl fullWidth>
                                        <InputLabel id="checkintiming-simple-select-label" error={Boolean(touched.checkintiming && errors.checkintiming)}>Time *</InputLabel>
                                        <Select
                                            labelId="checkintiming-simple-select-label"
                                            id="checkintiming-simple-select"
                                            label="Time *"
                                            {...getFieldProps('checkintiming')}
                                            error={Boolean(touched.checkintiming && errors.checkintiming)}
                                        >
                                            {
                                                timings.map(item => (<MenuItem key={item} value={item}>{item}</MenuItem>))
                                            }
                                        </Select>
                                    </FormControl>

                                </Box>
                                <FormLabel sx={{ fontSize: 12 }}>{'* Earliest check in time is 10:30AM. Please contact our staff 24 hours in advance for any special request. Surcharge may apply.'}</FormLabel>
                            </div>

                            <div>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            label="Check-out Date *"
                                            name="checkindate"
                                            closeOnSelect={true}
                                            disablePast={true}
                                            value={formik.values.checkoutdate}
                                            minDate={formik.values.checkindate}
                                            onChange={(value) => formik.setFieldValue('checkoutdate', value)}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => <TextField fullWidth {...params} error={Boolean(touched.checkoutdate && errors.checkoutdate)} />}
                                        />
                                    </LocalizationProvider>
                                    <Box sx={{ px: 1 }} />

                                    <FormControl fullWidth>
                                        <InputLabel id="checkouttiming-simple-select-label" error={Boolean(touched.checkouttiming && errors.checkouttiming)}>Time *</InputLabel>
                                        <Select
                                            labelId="checkouttiming-simple-select-label"
                                            id="checkouttiming-simple-select"
                                            label="Time *"
                                            {...getFieldProps('checkouttiming')}
                                            error={Boolean(touched.checkouttiming && errors.checkouttiming)}
                                        >
                                            {
                                                timings.map(item => (<MenuItem key={item} value={item}>{item}</MenuItem>))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <FormLabel sx={{ fontSize: 12 }}>{'* Latest check out time is 5:30PM. Please contact our staff 24 hours in advance for any special request. Surcharge may apply.'}</FormLabel>
                            </div>

                            <FormControl>
                                <FormLabel id="fleatickprotection-radio-buttons-group" sx={{ fontSize: 12 }} error={Boolean(touched.fleatickprotection && errors.fleatickprotection)}>Flea & Tick protection is compulsory *</FormLabel>
                                <RadioGroup
                                    aria-labelledby="fleatickprotection-radio-buttons-group"
                                    name="fleatickprotection-radio-buttons-group"
                                    {...getFieldProps('fleatickprotection')}
                                    sx={{ fontSize: 8 }}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes. Apply Revolution for my cat upon check-in (S$20)" />
                                    <FormControlLabel value="No" control={<Radio />} label="No. I will apply before check-in" />
                                </RadioGroup>
                            </FormControl>

                            <FormControl>
                                <FormLabel id="cameraaccess-radio-buttons-group" sx={{ fontSize: 12 }} error={Boolean(touched.cameraaccess && errors.cameraaccess)}>Camera Access *</FormLabel>
                                <RadioGroup
                                    aria-labelledby="cameraaccess-radio-buttons-group"
                                    name="cameraaccess-radio-buttons-group"
                                    {...getFieldProps('cameraaccess')}
                                    sx={{ fontSize: 8 }}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes. One-time activation fee ($10)" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>

                            <FormControl>
                                <FormLabel id="disposablescratchpad-radio-buttons-group" sx={{ fontSize: 12 }} error={Boolean(touched.disposablescratchpad && errors.disposablescratchpad)}>Do you need disposable scratch pad?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="disposablescratchpad-radio-buttons-group"
                                    name="disposablescratchpad-radio-buttons-group"
                                    {...getFieldProps('disposablescratchpad')}
                                    sx={{ fontSize: 8 }}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes. Disposable scratch pad (S$8)" />
                                    <FormControlLabel value="No" control={<Radio />} label="No. I will bring my own" />
                                </RadioGroup>
                            </FormControl>

                            <TextField
                                fullWidth
                                label="Remarks (Optional)"
                                {...getFieldProps('remarks')}
                            />

                            <FormControlLabel
                                {...getFieldProps('agreetermone')}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }} />}
                                label="I acknowledge and consent that should my pet be found infested with parasites (ticks/mites/fleas) or has a case of ringworm (or any form of contagious fungal infection), surcharges will apply for professional disinfection (whichever applicable)."
                                labelPlacement="end"
                            />
                            {Boolean(touched.agreetermone && errors.agreetermone) 
                                && <Alert severity="error">Please accept before submit.</Alert>
                            }

                            <FormControlLabel
                                {...getFieldProps('agreetermtwo')}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }} />}
                                label={<label>I understand that my booking will only be confirmed after I have submitted my cat's vaccination records + payment of 50% deposit, and that the balance will be due upon Check-in. I have read and agree the <a className="underline" href={TermsAndConditions} target="_blank">Terms and Conditions</a>.</label>}
                                labelPlacement="end"
                            />
                            {Boolean(touched.agreetermtwo && errors.agreetermtwo) 
                                && <Alert severity="error">Please accept before submit.</Alert>
                            }

                            <div>
                                <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>

                                <span className='text-orange-500'>Consent Form for Animal Compatibility</span>
                                Animals can be boarded together subject to the following:
                                <br></br>
                                <br></br>
                                (1) They are compatible
                                <br></br>
                                (2) No overcrowding (max 1 cat for classic cabin & premium classic cabin, max 2 cats for premium suite & loft suite cabin and max 3 cats for premium loft cabin) 
                                <br></br>
                                (3) Cats can only stay together if they are from same household
                                <br></br>
                                <br></br>
                                By submitting the registration form, you would like to board your cat(s) together and share the common facilities in our Cat Inn (this applies to any area of our boarding facility, i.e., grooming, play area, cabin, etc.)
                                <br></br>
                                <br></br>
                                *We need to ensure that cats kept together are compatible and minimally do not fight each other. 
    Please take note we will separate the cats during their stay if our staff see the cats fighting in the cabin.

                                </Box>
                            </div>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={props.handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button variant="outlined" type="button" onClick={() => {
                                    submitForm();
                                }}>Submit</Button>
                            </Box>

                        </Stack>
                    </ThemeProvider>
                </Form>
            </FormikProvider>
        </>
    );
}
